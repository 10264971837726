import { request } from "@/utils/request";

// 获取招聘列表

// 获取招聘列表
export function recruitList(params) {
  return request({
    url: `/api/recruit/list`,
    method: "get",
    params,
  });
}

// 获取招聘详情
export function recruitDetail(recruitId) {
    return request({
      url: `/api/recruit/${recruitId}`,
      method: "get"
    });
}

// 获取动态列表 1公司 2行业
export function newsPage(params) {
    return request({
      url: `/api/news/list`,
      method: "get",
      params
    });
}

// 获取动态详情
export function newsDetail(newsId) {
    return request({
      url: `/api/news/${newsId}`,
      method: "get"
    });
}


// 联系我们新增
export function addContact(data) {
    return request({
    url: "/api/contact/us",
      method: "post",
      data
    });
}
  // 新增
export function addJob(data) {
  return request({
  url: "/api/job",
    method: "post",
    data
  });
}

