// import Vue from "vue";
import axios from "axios";
// import store from "@/store";
// import { Message } from "element-ui";
// import getToken from "@/permission/getToken";
// import router from "@/router";

export function request(options) {
  // const status = options.status === false ? false : true;
  // Message.closeAll();
  let header = {
  //   token: getToken(),
  };
  return new Promise((resolve, reject) => {
    // if (status) store.state.main.loading = true; //修改加载中状态
    const instance = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      headers: {
        ...header,
      }, //请求头
      timeout: 45 * 1000, //超时时间
    });
    instance.interceptors.request.use(
      (config) => {
        // if (status) store.state.loading = true;
        // config.data.userId = store.state.userInfo.id //统一传入userId
        console.log(config);
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    instance(options)
      .then((response) => {
        // if (status) store.state.main.loading = false;
        // if (response.data.code == 300) {
        //   // if (status) store.state.main.loading = false;
        //   // Message.error("登录失效，请重新登录");
        //   // // location.href = "#/login/loginin";
        //   // router.apps[0].$router.replace({
        //   //   name: "loginin",
        //   // });
        //   return;
        // }
        // if (
        //   response.data.code !== null &&
        //   parseInt(response.data.code) !== 0 &&
        //   response.data.code !== 200
        // ) {
        //   // if (status) store.state.main.loading = false;
        //   let err = response.data.message ? response.data.message : response.data.code_desc ? response.data.code_desc : '失败';
        //    response.data && Message.error(err)
        //   reject(response.data);
        //   return;
        // }
        resolve(response);
      })
      .catch((error) => {
        // Message.closeAll();
        // // if (status) store.state.main.loading = false;
        // let response = JSON.parse(error.request.response);
        // let err = response.message ? response.message : response.code_desc ? response.code_desc : '失败';
        // if (error.config.url.indexOf('pointValue') == -1) {
        //   Message.error(err);
        // } else {
        //   response = {
        //     ...response,
        //     handle: true
        //   }
        // }
        reject(error);
      });
  });
}
